import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";
import SensorInfo from "./IndividualSensor";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Box } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/system';


const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ThreeGraph = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [machineId, setMachineId] = useState<number | undefined>(undefined);
  const [actualData, setActualData] = useState<{
    machine_name: string;
    plant_name: string;
    sensor_used_tag: string;
    threshold_alert: number;
    value_list: any;
  } | null>(null);
  const [actualDataH, setActualDataH] = useState<{
    machine_name: string;
    plant_name: string;
    sensor_used_tag: string;
    threshold_alert: number;
    value_list: any;
  } | null>(null);
  const [predData, setPredData] = useState<{
    machine_name: string;
    plant_name: string;
    sensor_used_tag: string;
    threshold_alert: number;
    prediction_list: any;
  } | null>(null);

  const [sensorData, setSensorData] = useState<
    {
      machine_name: string,
      sensor_kind: string,
      sensor_used_tag: string,
      threshold_alert: number,
      threshold_percent_alarm: number,
      threshold_percent_warning: number,
      machine_kind: string
    } | null
  >(null);

  //ログインしてなかったらトップページにリダイレクトする
  const navigate = useNavigate();
  const apiUrlPostAct = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/sensor_values_daily/`;
  const apiUrlPostActH = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/sensor_values_hourly/`;
  const apiUrlPostPred = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/prediction_values/`;
  //クエリパラメータを取得する
  const location = useLocation();

  //Status Modalの開閉状態を管理する
  const [statusModalIsOpen, setStatusModalIsOpen] = useState(false);
  const openStatusModal = () => {
    setStatusModalIsOpen(true);
  };
  const closeStatusModal = () => {
    setStatusModalIsOpen(false);
  };

  //クエリパラメータからmachine_idを取得する。
  useEffect(() => {
    //クエリパラメータからmachine_idを取得する。
    const fetchMID = async () => {
      const searchParams = new URLSearchParams(location.search);
      const queryMachineId = searchParams.get("machine_id");
      //もしクエリパラメータにmachine_idがあれば、それをmachineIdにセットする
      if (queryMachineId) {
        const mid: number = parseInt(queryMachineId);
        //整数に変換してからセットする
        setMachineId(mid);
      } else {
        setMachineId(props.machine_id);
      }
    };
    fetchMID();
  }, [props.machine_id, location.search]);

  useEffect(() => {
    //クエリパラメータからmachine_idを取得する。
    const fetchData = async () => {
      try {
        //今から２年前の日付をYYYY-MM-DD形式で取得する。
        const dt = new Date();
        const dt2m = new Date(
          dt.getFullYear() - 2,
          dt.getMonth(),
          dt.getDate()
        );
        const dt2p = new Date(
          dt.getFullYear() + 2,
          dt.getMonth(),
          dt.getDate()
        );
        const bgn_Dt = dt2m.toISOString().split("T")[0];

        //実測値を取得して所持する
        const res_act = await axios.post(
          apiUrlPostAct,
          {
            machine_id: machineId,
            bgn_dt: bgn_Dt,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.localJWT}`,
            },
          }
        );
        setActualData(res_act.data);
        //実測値を取得して所持する(１時間値)
        const res_act_hour = await axios.post(
          apiUrlPostActH,
          {
            machine_id: machineId,
            bgn_dt: dt2m,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.localJWT}`,
            },
          }
        );
        setActualDataH(res_act_hour.data);

        //予測値を取得して所持する
        const res_pred = await axios.post(
          apiUrlPostPred,
          {
            machine_id: machineId,
            bgn_dt: dt2m,
            end_dt: dt2p,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.localJWT}`,
            },
          }
        );
        setPredData(res_pred.data);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    const apiUrlGet = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/alert_settings/${machineId}/`;
    axios
      .get(apiUrlGet, {
        headers: {
          Authorization: `JWT ${localStorage.localJWT}`,
        },
      }).then((response) => {
        setSensorData(response.data);
        setLoading(false);
        console.log(response.data);
      }).catch((error) => {
        console.error(error);
        setLoading(false);
      });
    fetchData();
  }, [machineId]);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }
  if (!actualData || !predData || !actualDataH || !sensorData) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }
  const threshold_alert = sensorData.threshold_alert;
  const threshold_warning_p = sensorData.threshold_percent_warning;
  const threshold_alarm_p = sensorData.threshold_percent_alarm;
  const threshold_warning = threshold_alert * threshold_alarm_p / 100;
  const threshold_alarm = threshold_alert * threshold_warning_p / 100;
  //actualData.value_listのdateを配列に入れる
  const xValuesPred = predData.prediction_list.map(
    (x: any) => x.prediction_time
  );
  const yValuesPred = predData.prediction_list.map((x: any) => x.y_hat);
  const yValuesPredHatU = predData.prediction_list.map(
    (x: any) => x.y_hat_upper
  );
  const yValuesPredHatL = predData.prediction_list.map(
    (x: any) => x.y_hat_lower
  );

  const xValuesAct = actualData.value_list.map((x: any) => x.date);
  const yValuesAct = actualData.value_list.map((x: any) => x.value);
  const minDate = new Date(
    Math.min(...xValuesAct.map((x: any) => new Date(x).getTime()))
  );
  const maxDate = new Date(
    Math.max(...xValuesPred.map((x: any) => new Date(x).getTime()))
  );
  const xValuesActH = actualDataH.value_list.map((x: any) => x.datetime);
  const yValuesActH = actualDataH.value_list.map((x: any) => x.value);
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<InfoIcon />}
        sx={{ backgroundColor: '#00b894' }}
        onClick={() => {
          setStatusModalIsOpen(true);
        }}
      >
        センサー情報
      </Button>
      <StyledModal
        open={statusModalIsOpen}
        onClose={closeStatusModal}>
        <div>
          <SensorInfo machine_id={machineId} />
          <Button variant="contained"
            onClick={closeStatusModal}
            sx={{ backgroundColor: '#00b894' }}>
            Close
          </Button>
        </div>
      </StyledModal>
      <Plot
        data={[
          {
            x: xValuesAct,
            y: yValuesAct,
            type: "scatter",
            mode: "markers",
            name: "センサー（日平均値）",
          },
          {
            x: xValuesActH,
            y: yValuesActH,
            type: "scatter",
            mode: "markers",
            name: "センサー（1時間値）",
          },
          {
            x: xValuesPred,
            y: yValuesPred,
            type: "scatter",
            mode: "lines",
            line: { color: "blue" },
            name: "予測データ",
          },
          {
            x: xValuesPred,
            y: yValuesPredHatU,
            type: "scatter",
            mode: "lines",
            name: "予測データ上限",
            line: { color: "rgba(0, 0, 255, 0.1)" },
            showlegend: false,
          },
          {
            x: xValuesPred,
            y: yValuesPredHatL,
            type: "scatter",
            mode: "lines",
            name: "予測データ下限",
            line: { color: "rgba(0, 0, 255, 0.1)" },
            fill: "tonexty",
            showlegend: false,
          },
          {
            x: [minDate, maxDate],
            y: [threshold_warning, threshold_warning],
            type: "scatter",
            mode: "lines",
            name: "閾値（警報）",
            showlegend: true, // 凡例に表示する
          },
          {
            x: [minDate, maxDate],
            y: [threshold_alarm, threshold_alarm],
            type: "scatter",
            mode: "lines",
            name: "閾値（注意喚起）",
            showlegend: true, // 凡例に表示する
          },
        ]}
        layout={{
          title: "センサーデータ予測グラフ",
          xaxis: {
            type: "date",
            tickformat: "%Y-%m-%d",
            rangeselector: {
              buttons: [
                {
                  count: 1,
                  label: "1ヶ月",
                  step: "month",
                  stepmode: "backward",
                },
                {
                  count: 6,
                  label: "6ヶ月",
                  step: "month",
                  stepmode: "backward",
                },
                {
                  count: 1,
                  label: "1年",
                  step: "year",
                  stepmode: "backward",
                },
                {
                  step: "all",
                  label: "全期間",
                },
              ],
            },
            rangeslider: {}, // x軸のラベルの表示形式を指定
          },
          yaxis: {
            title: "加速度",
            autorange: true,
            fixedrange: false,
          },
        }}
        config={{ displayModeBar: false }}
        style={{ width: "100%", height: "700px" }}
      />
    </div>
  );
};

export default ThreeGraph;
