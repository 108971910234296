import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useEffect, useState, ChangeEvent } from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Copyright from "../../genericTemplate/Copyright";
// import Alert from "@mui/material/Alert";
// import Stack from "@mui/material/Stack";
const apiUrl = process.env.REACT_APP_DEV_API_URL;
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export type User = {
  username: string;
  password: string;
};

export type JWTCreate = {
  access: string;
  refresh: string;
};

export const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<User>();

  const navigate = useNavigate();
  // 編集モーダルの開閉状態
  const [modalOpen, setModalOpen] = useState(false);
  const [otp, setOTP] = useState('');
  const [passwordStatus, setPasswordStatus] = useState(false);
  const [token, setToken] = useState("");
  const [tryUser, setTryUser] = useState("");
  const handleClose = () => {
    setModalOpen(false);
  };
  const submitOTP = () => {
    // one time password入力モーダルに入力された数字をbackendに送る
    axios.post(`${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/otp/auth/`, {
      username: tryUser,
      otp: otp,
    }).then((res: AxiosResponse) => {
      localStorage.setItem("localJWT", token);
      setModalOpen(false);
      navigate("/home");
    }).catch((err: AxiosError) => {
      alert("One Time Passwordが違います。");
      setOTP("");
    });
    //localStorage.clear();
    //localStorage.setItem("localJWT", token);
    //認証後のページにリダイレクト
    //setModalOpen(false);
    //
  };
  const onSubmit: SubmitHandler<User> = async (data) => {
    const username = data.username;
    setTryUser(username);
    localStorage.clear();
    await axios
      .post(`${apiUrl}authen/jwt/create`, {
        username: data.username,
        password: data.password,
      })
      .then((res: AxiosResponse<JWTCreate>) => {
        const { data, status } = res;
        // one time passwordを生成するAPIを叩く
        axios.post(`${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/otp/create/`, {
          username: username,
        }).then((res: AxiosResponse) => {
          // one time posword 入力モーダルを立ち上げる
          setModalOpen(true);
        }).catch((err: AxiosError) => {
          alert("One Time Passwordの生成に失敗しました");
        });
        
        if (status === 200) {
          setPasswordStatus(true);
          setToken(data?.access);
        }
        
      })
      .catch((err) => {
        if( err.response.data?.message == 1){
          alert("アカウントが存在しません");
        }
        alert("UsenameかPasswordが違います");
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username_register"
              type="username"
              label="User Name"
              // name="email"
              // autoComplete="email"
              autoFocus
              {...register("username", { required: true })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              // name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              {...register("password", { required: true })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Button
              component={Link}
              to="/signup"
              type="submit"
              fullWidth
              variant="text"
              sx={{ mt: 3, mb: 2 }}
              size="small"
            >
              アカウントをお持ちでない方はこちら
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      <Dialog open={modalOpen} onClose={handleClose}>
        <DialogTitle>ワンタイムパスワード入力</DialogTitle>
        <DialogContent>
          <TextField
            label="ワンタイムパスワード"
            variant="outlined"
            type="number"
            value={otp}
            onChange={(e) => setOTP(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button onClick={() => submitOTP()} color="error">送信</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};
