import React, { useState, useEffect } from "react";
import { Button } from '@mui/material';
import Select from "react-select";
import TextField from "@mui/material/TextField";
import TableCell from "@mui/material/TableCell";
//Gridのインポート
import Grid from "@mui/material/Grid";
import axios from "axios";
import "./modal_daialog.css";

import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import {
  updateSubmitting,
  selectIsSubmitting,
} from "./sensorSlice";

type ModalContentProps = {
  machineId: number | undefined; // もしくは適切な型に置き換える
  handleCloseModal: () => void;
};

function ModalContent({ machineId, handleCloseModal }: ModalContentProps) {
  //////
  const isUploaded = useSelector(selectIsSubmitting);
  
  const [optionsSelect, setOptionsSelect] = useState<{ value: string, label: string }[]>([]);
  useEffect(() => {
    const apiUrlGET = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/tag_cls_list/`;
    axios.get(apiUrlGET, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.localJWT}`,
      },
    }).then((res) => {
      console.log(res.data);
      const values = Object.values(res.data).map((item:any) => ({
        value: item.cls_id,
        label: item.name, // もしくは適切なプロパティ名に置き換えてください
      }));
      console.log(values);
      setOptionsSelect(values);
    }).catch((error) => console.error(error));;
  },[])
  

  const [machine_options, setMachineSelect] = useState<{ label: string, value: string }[]>([]);
  useEffect(() => {
    const apiUrlGET = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/machine_kind/`;
    axios.get(apiUrlGET, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.localJWT}`,
      },
    }).then((res) => {
      console.log(res.data);
      const values = Object.values(res.data).map((item:any) => ({
        label: item.machine_kind,
        value: item.machine_kind, // もしくは適切なプロパティ名に置き換えてください
      }));
      setMachineSelect(values);
    }).catch((error) => console.error(error));;
  },[])

  const defaultTag = [
    { tag_id: 0, tag_name: "" },
  ]

  const [selectedMachineOption, setSelectedMachineOption] = useState<{ label: string, value: string } | null>({ label: '', value: '' });
  const [selectedTag, setSelectedTag] = useState([optionsSelect[0]]);
  const [tagList, setTagList] = useState([defaultTag[0]]);

  const [sensorData, setSensorData] = useState<{
    machine_name: string;
    machine_kind: string,
    sensor_kind: string;
    sensor_used_tag: string;
    threshold_alert: number;
    threshold_percent_alarm: number;
    threshold_percent_warning: number;

  } | null>(null);
  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleMachineInputChange = (selectedOption: { label: string, value: string } | null) => {
    setSelectedMachineOption(selectedOption);
    console.log(selectedOption);
    let sensorMachineName = sensorData?.machine_name ?? ""; // machine_name の値を外部の変数に格納
    let sensorKindValue = sensorData?.sensor_kind ?? "";
    let machineKindValue = sensorData?.machine_kind ?? "";
    let sensorThereshold = sensorData?.threshold_alert ?? 0;
    let sensorPercentAlarm = sensorData?.threshold_percent_alarm ?? 0;
    let sensorPercentWarning = sensorData?.threshold_percent_warning ?? 0;
    const vals = selectedOption?.value as string;
    //setSensorDataのmachine_nameを変更する
    setSensorData((prevData) => ({
      ...prevData,
      machine_name: sensorMachineName,
      sensor_kind: sensorKindValue,
      sensor_used_tag: prevData?.sensor_used_tag ?? "",
      threshold_alert: sensorThereshold,
      threshold_percent_warning: sensorPercentWarning,
      threshold_percent_alarm: sensorPercentAlarm,
      machine_kind: vals,
    }));
  };
  const handleInputChange =
    (fieldName: string) =>
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target.value;
        let sensorMachineName = sensorData?.machine_name ?? ""; // machine_name の値を外部の変数に格納
        let sensorKindValue = sensorData?.sensor_kind ?? "";
        let machineKindValue = sensorData?.machine_kind ?? "";
        let sensorThereshold = sensorData?.threshold_alert ?? 0;
        let sensorPercentAlarm = sensorData?.threshold_percent_alarm ?? 0;
        let sensorPercentWarning = sensorData?.threshold_percent_warning ?? 0;
        if (fieldName === "machine_name") {
          sensorMachineName = target; // machine_name の値を外部の変数に格納
        }
        if (fieldName === "machine_kind") {
          machineKindValue = target; // sensor_kind の値を外部の変数に格納
        }
        if (fieldName === "sensor_kind") {
          sensorKindValue = target; // sensor_kind の値を外部の変数に格納
        }
        if (fieldName === "threshold_alert") {
          sensorThereshold = parseFloat(target); // sensor_kind の値を外部の変数に格納
        }
        if (fieldName === "threshold_percent_warning") {
          sensorPercentWarning = parseFloat(target); // sensor_kind の値を外部の変数に格納
        }
        if (fieldName === "threshold_percent_alarm") {
          sensorPercentAlarm = parseFloat(target); // sensor_kind の値を外部の変数に格納
        }
        //setSensorDataのmachine_nameを変更する
        setSensorData((prevData) => ({
          ...prevData,
          machine_name: sensorMachineName,
          sensor_kind: sensorKindValue,
          sensor_used_tag: prevData?.sensor_used_tag ?? "",
          threshold_alert: sensorThereshold,
          threshold_percent_warning: sensorPercentWarning,
          threshold_percent_alarm: sensorPercentAlarm,
          machine_kind: machineKindValue,
        }));
      };
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false); // 確認モーダルの表示状態を管理
  const handleConfirm = () => {
    setIsConfirmModalOpen(true);
  };

  const handleCancelConfirm = () => {
    setIsConfirmModalOpen(false);
  };
  const dispatch: AppDispatch = useDispatch();
  const handleFinalSubmit = async () => {
    // 実際のデータ送信処理を実行する
    const apiUrlPost = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/alert_settings/${machineId}/update/`;

    //実測値を取得して所持する
    const res_act = axios.post(apiUrlPost, sensorData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.localJWT}`,
      },
    }).then((res) => {
      console.log(res);
    }).catch((err) => {
      console.log(err);
      alert(err.response.data.message);
    });
    //過去のタグをいったん削除する。
    Object.keys(tagList).forEach((key) => {
      const keyInt = parseInt(key);
      const tagdata = tagList[keyInt];
      //tagdataからtag_idを取得して文字列に変換する
      const tagId = tagdata.tag_id.toString();
      const apiUrlTagDel = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/machine_tag/${tagId}/delete/`;
      const postData = {
        "updated_at": null
      };
      const res_tag = axios.post(apiUrlTagDel, postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.localJWT}`,
        },
      }
      );
    });

    // machine_id
    // 選択されているtagを登録する。
    const apiUrlTagPost = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/machine_tag/`;
    Object.keys(selectedTag).forEach((key) => {
      //keyを整数に変換する
      const keyInt = parseInt(key);
      const tagdata = selectedTag[keyInt];
      const postData = {
        "machine": machineId,
        "tag_name": tagdata.label,
      }
      const res_tag = axios.post(apiUrlTagPost, postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.localJWT}`,
        },
      }
      );
    });
    //isSubmittingをfalseにする
    dispatch(updateSubmitting(true));
    // モーダルを閉じる
    handleCloseModal();
    setIsSubmit(true);
  };
  useEffect(() => {
    //const machine_id = props.machine_id;
    const apiUrlGet = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/alert_settings/${machineId}/`;
    setLoading(true);
    axios
      .get(apiUrlGet, {
        headers: {
          Authorization: `JWT ${localStorage.localJWT}`,
        },
      })
      .then((response) => {
        setSensorData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
    //consoleに出力する
    //machine_idに紐づいているtagを取得する
    const apiUrlTagGet = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/machine_tag_all/?machine=${machineId}`;
    axios.get(apiUrlTagGet, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.localJWT}`,
      },
    }).then((response) => {
      setTagList(response.data);
      const newTagList: { value: string, label: string }[] = response.data.map((item: { tag_id: string, tag_name: string }) => ({
        value: item.tag_id,
        label: item.tag_name
      }));
      setSelectedTag(newTagList);
      console.log(newTagList);

      setIsSubmit(false);
    });

  }, [machineId]);
  return (
    <div>
      <div className="modal-overlay" onClick={handleCloseModal} />
      <div className="modal-dialog">
        <h4>センサー情報編集</h4>
        <Grid container direction="column" spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="名称"
                variant="standard"
                value={sensorData?.machine_name}
                onChange={handleInputChange("machine_name")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="機器タイプ"
                variant="standard"
                value={sensorData?.machine_kind}
                onChange={handleInputChange("machine_kind")}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Select
                options={machine_options}
                value={selectedMachineOption}
                onChange={handleMachineInputChange}
                placeholder="選択してください"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="センサー種類"
                variant="standard"
                value={sensorData?.sensor_kind}
                onChange={handleInputChange("sensor_kind")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="閾値"
                variant="standard"
                value={sensorData?.threshold_alert}
                type="number"
                onChange={handleInputChange("threshold_alert")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="注意喚起（%）"
                variant="standard"
                value={sensorData?.threshold_percent_warning}
                type="number"
                onChange={handleInputChange("threshold_percent_warning")}
                InputLabelProps={{
                  shrink: true,
                }}

                error={sensorData?.threshold_percent_warning !== undefined && (sensorData.threshold_percent_warning < 0 || sensorData.threshold_percent_warning > 100)}
                helperText={
                  (sensorData?.threshold_percent_warning !== undefined && (sensorData.threshold_percent_warning < 0 || sensorData.threshold_percent_warning > 100))
                    ? "有効範囲を超えています"
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              label="警報喚起（%）"
              variant="standard"
              value={sensorData?.threshold_percent_alarm}
              type="number"
              onChange={handleInputChange("threshold_percent_alarm")}
              InputLabelProps={{
                shrink: true,
              }}
              error={sensorData?.threshold_percent_alarm !== undefined && (sensorData.threshold_percent_alarm < 0 || sensorData.threshold_percent_alarm > 100)}
              helperText={
                (sensorData?.threshold_percent_alarm !== undefined && (sensorData.threshold_percent_alarm < 0 || sensorData.threshold_percent_alarm > 100))
                  ? "有効範囲を超えています"
                  : ""
              }
            />
          </Grid>
        </Grid>
        <Select
          options={optionsSelect}
          isMulti
          menuPlacement="auto"
          menuPortalTarget={document.body}
          value={selectedTag}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 })
          }}
          onChange={(selectedValues) => setSelectedTag(selectedValues as { value: string; label: string; }[])}
        />
        <Button variant="contained" onClick={handleConfirm}>データ送信</Button>
        <Button variant="outlined" onClick={handleCloseModal}>閉じる</Button>

        {isConfirmModalOpen && (
          <div className="modal-overlay">
            <div className="modal-dialog">
              <h4>確認</h4>
              <p>このデータを変更してもいいですか？</p>
              <div className="modal-button-group">
                <Button
                  variant="contained"
                  className="modal-confirm-button"
                  onClick={handleFinalSubmit}
                >
                  はい
                </Button>
                <Button
                  className="modal-cancel-button"
                  onClick={handleCancelConfirm}
                >
                  キャンセル
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ModalContent;
